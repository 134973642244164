<template>
    <div class="memod-list-tab-bar" :class="{ 'is-subcategories' : isSubcategories }">
        <div class="container">
            <div class="links">
                <template v-for="(tab, index) in tabsList">
                    <div
                        v-if="tab.hasOwnProperty('isLoggedUserProfile') ? tab.isLoggedUserProfile : true"
                        :key="index"
                        class="link"
                    >
                        <router-link
                            :to="{ name: tab.name }"
                            class="body-text-sb"
                        >
                            <component :is="tab.iconComponent" v-if="tab.iconComponent" :color="$route.name == tab.name ? primaryColor : '#EBEBF5'" />
                            {{ tab.label }}
                        </router-link>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { primaryColor } from "@assets/scss/_variables.scss";

export default {
    name: "HomeTabBar",
    props: {
        currentList: {
            type: String,
            required: true
        },
        tabsList: {
            type: Array,
            required: true
        },
        isSubcategories: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            primaryColor
        };
    }
}
</script>

<style lang="scss" scoped>
.memod-list-tab-bar {
    top: 0;
    left: 0;
    width: 100%;
    position: relative;
    display: flex;
    border-bottom: 1px solid #4b4b4b;
    z-index: 9;

    .container {
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: flex-end;
    }

    .links {
        display: flex;
        padding-top: 25px;
        padding-bottom: 25px;
        margin-left: auto;
        margin-right: auto;

        .link {
            a {
                display: block;
                margin-left: 65px;
                cursor: pointer;
                padding: 6px 20px;
                border-radius: 100px;
                color: white;
                text-decoration: none;

                @media(max-width: $lg) {
                    margin-left: 0;
                }

                &.router-link-active {
                    background-color: $primary-color;
                }
            }
        }
    }

    &.is-subcategories {
        padding-top: 30px;

        .container {
            display: block;
        }

        .links {
            padding: 0;
            display: flex;
            justify-content: space-around;
            max-width: 70%;
            overflow-x: auto;

            @media(max-width: $lg) {
                max-width: 90%;
            }

            @media(max-width: $md) {
                max-width: 100%;
                justify-content: flex-start;
            }

            .link {
                a {
                    display: block;
                    color: rgba(235, 235, 245, 0.3);
                    font-size: 16px;
                    line-height: 16px;
                    padding-top: 20px;
                    padding-bottom: 15px;
                    font-weight: 700;
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 5px;
                    }

                    &.router-link-active {
                        background-color: transparent;
                        color: $primary-color;
                        border-bottom: 2px solid $primary-color;
                        border-radius: 0;
                    }
                }
            }
        }
    }
}
</style>
